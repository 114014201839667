import React, { useEffect, useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { inDevelopment } from "utils/env-utils";

// Contexts
import { UserContext } from "contexts/UserContext";
import { ProjectsContext } from "contexts/ProjectsContext";

import { useSpring, animated } from "react-spring";

import "./index.scss";
import { Header } from "components/Header";

export const Feed = () => {
  const { users, usersLoaded } = useContext(UserContext);
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);

  const [focusedProj, setFocusedProj] = useState({ bg: "#FFFFFF", img: "" });
  const props = useSpring({ backgroundColor: focusedProj.bg });

  // Data testing
  if (inDevelopment) {
    console.group("Data Status");
    console.log(loaded ? "I'm done loading" : "I'm still loading");
    console.log(
      dataLoaded
        ? "and my data is done loading too!"
        : "but my data is still loading",
    );
    console.log(projects);
    console.groupEnd();
  }

  return (
    <div className="page-background">
      <animated.div className="page-container" style={{ ...props }}>
        {projects.map((project) => (
          <Link
            to={`/${project.slug}`}
            key={project.slug}
            className="intro-text"
            // onMouseEnter={() => setFocusedProj({bg:`${project.bannerImageBackgroundColor}`})} This is breaking. Do not use
          >
            Jono is {project.jonoIs}
          </Link>
        ))}
        <Link to={`/a-cool-guy`} key={"about"} className="intro-text">
          Who is Jono?
        </Link>
      </animated.div>
    </div>
  );
};
