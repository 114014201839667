import React from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

import { useEdgeManager } from "contexts/EdgeManager";


export const UserButton = ({ user }) => {
  return (
    <Link
      className="button tall"
      to={`/showinghisworkto/${user.slug}`}
      onMouseEnter={() => {
        gsap.to("#edge", {backgroundColor: user.edgeColor})
        gsap.to("#logo", {fill: user.edgeColor})
      }}
      onMouseLeave={() => {
        gsap.to("#edge", {backgroundColor: "white"})
        gsap.to("#logo", {fill: "currentColor"})
      }}
    >
      {user.emoji} {user.username}
    </Link>
  );
};
