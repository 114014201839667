import React, {useContext, useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import gsap from "gsap";

// Components
import { ProjectCard } from "components/ProjectCard";

//Contexts and Utils
import { UserContext } from "contexts/UserContext";
import { ProjectsContext } from "contexts/ProjectsContext";
import { AnimatedText } from "utils/AnimatedText";
import { useEdgeManager } from "contexts/EdgeManager";

// Style
import s from "./index.module.scss";

export const Greeting = () => {
  // var tl = gsap.timeline({ paused: false, id: "timeline" });
  const [greeting, setGreeting] = useState([]);
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);
  const { users, usersLoaded } = useContext(UserContext);
  const {setEdge } = useEdgeManager();

  let { slug: urlSlug } = useParams(),
    navigate = useNavigate();

  const userSlugs = users.map((user) => {
    return user.slug;
  });

  const slugMatch = userSlugs.includes(urlSlug);

  const user = users.find(({ slug }) => {
    return slug === urlSlug;
  });

  useEffect(() => {
    setEdge(true);
  }, [setEdge]);

  return (
    <section className={s.homeHero}>
      {user && (
        <>
          <AnimatedText>{user.message1}</AnimatedText>
          <div className={s.selectedProjects}>
            {user.selectedProjects.map((project, i) => (
              <ProjectCard project={project} key={i} />
            ))}
          </div>
        </>
      )}
    </section>
  );
};
