import { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap, { tl } from "gsap";
import SplitText from "gsap/SplitText";

export const AnimatedText = ({ children }) => {
  var textTimeline = gsap.timeline({ paused: true, id: "timeline" });
  const [animationPlayed, setAnimationPlayed] = useState(false);

  const fontWeightItems = useRef();
  const MAX_DISTANCE = 300; // Adjust the maximum distance for•
  const MAX_FONT_WEIGHT = 800; // Maximum font weight
  const MIN_FONT_WEIGHT = 300; // Minimum font weight

  //• Split up any text with the data attribute into individual. fontWeightItems. forEach ( (item) =>• {
  const mySplitText = new SplitText(fontWeightItems.current, {
      type: "words,chars",
    }),
    chars = mySplitText.chars;
  // Initial animation
  useEffect(() => {
    if (fontWeightItems.current && !animationPlayed) {
      console.log(chars, "Running");
      textTimeline
        .fromTo(
          chars,
          {
            duration: 1,
            scale: 0,
            transformOrigin: "0% 50% -50",
            y: 20,
            fontWeight: 100,
            ease: "back.out",
          },
          {
            scale: 1,
            transformOrigin: "0",
            fontWeight: 800,
            y: 0,
            stagger: 0.01,
          }
        )
        .to(chars, { fontWeight: 100, stagger: 0.01 }, "-=0.8");
      textTimeline.play(0);
      setAnimationPlayed(true);
    } else {
      console.log("I already played!");
      textTimeline.progress(1, false);
    }
  }, [animationPlayed, chars, textTimeline]);

  useEffect(() => {
    // Mousemove handler
    const handleMouseMove = (event) => {
      const mouseX = event.pageX;
      const mouseY = event.pageY;

      chars.forEach((char) => {
        //Get the center of each character and calculate the distance.
        const itemRect = char.getBoundingClientRect();
        const itemCenterX = itemRect.left + itemRect.width / 2 + window.scrollX;
        const itemCenterY = itemRect.top + itemRect.height / 2 + window.scrollY;

        const distance = Math.sqrt(
          Math.pow(mouseX - itemCenterX, 2) + Math.pow(mouseY - itemCenterY, 2)
        );

        // Map the distance to the font weight range
        let fontWeight =
          distance < MAX_DISTANCE
            ? gsap.utils.mapRange(
                0,
                MAX_DISTANCE,
                MIN_FONT_WEIGHT,
                MAX_FONT_WEIGHT,
                Math.max(0, MAX_DISTANCE - distance)
              )
            : MIN_FONT_WEIGHT;
        let fontTo = gsap.quickTo(char, "fontWeight", { duration: 0.5 });
        fontTo(fontWeight);
      });
    };
    document.addEventListener("mousemove", handleMouseMove);
    console.log("added");
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      console.log("removed");
    };
  }, [chars]);

  return (
    <h2 className="greeting" ref={fontWeightItems}>
      {children}
    </h2>
  );
};
