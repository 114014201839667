import React from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap/gsap-core";

import { useEdgeManager } from "contexts/EdgeManager";

// Style
import s from "./index.module.scss";

export const ProjectCard = ({ project }) => {
  return (
    <Link
      to={`/${project.slug}`}
      className={s.projectCard}
      onMouseEnter={() => {
        gsap.to("#edge", {backgroundColor: project.bannerImageBackgroundColor.hex})
        gsap.to("#logo", {fill: project.bannerImageBackgroundColor.hex})
      }}
      onMouseLeave={() => {
        gsap.to("#edge", {backgroundColor: "white"})
        gsap.to("#logo", {fill: "currentColor"})
      }}
    >
      <img src={project.tile} alt="tile" className={s.tile} />
      <h3 className={s.cardTitle}>{project.title}</h3>
    </Link>
  );
};
