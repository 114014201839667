import React, { useState, useEffect, useMemo } from "react";
import gsap, { drawSVG } from "gsap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./index.scss";

export function Loader({ setScrollDisabled, setLoaderDone }) {
  const navigate = useNavigate();
  var tl = useMemo(() => gsap.timeline(), []);
  // Get the slug
  let location = useLocation();

  const logoColors = ["red", "lime", "blue", "currentColor"];

  // Fancy animation stuff
  useEffect(() => {
    tl.to(".loader-animation", {
      opacity: "1",
      duration: 0,
    });
    tl.fromTo(
      ".jf-border",
      {
        drawSVG: "0",
      },
      {
        duration: 1,
        drawSVG: "100%",
        ease: "power2.inOut",
        stagger: {
          grid: [4, 4],
          from: "start",
          axis: "y",
          amount: 0.4,
        },
      },
      0,
    )
      .fromTo(
        ".j-path",
        {
          drawSVG: "0",
        },
        {
          duration: 1,
          drawSVG: "100%",
          stagger: 0.2,
        },
        1,
      )
      .fromTo(
        ".f-path",
        {
          drawSVG: "0",
        },
        {
          duration: 1,
          drawSVG: "100%",
          stagger: 0.2,
        },
        1,
      )
      .fromTo(
        ".loader-border",
        {
          drawSVG: "0",
        },
        {
          duration: 1,
          drawSVG: "100%",

          stagger: {
            grid: [4, 4],
            from: "start",
            axis: "y",
            amount: 0.1,
          },
        },
        1.13,
      )
      .call(() => (document.title = `Jono is home`))
      .to("#jf-loader-animation", { opacity: 0, duration: 1 }, 3.25) // fade the loader animation once it's over
      .call(() => setScrollDisabled(false)) // let the user begin to scroll as soon as the animation completes
      .to(".full-page-loader", { opacity: 0, duration: 3 }, 4.5) // fade in the content
      .call(
        () =>
          !location.hash &&
          location.pathname === "/" &&
          window.history.replaceState(null, "Jono is HELLO", "/#home"),
      )
      .call(() => setLoaderDone(true)); // set the loader as complete and destroy the loader

    // If there's a hash location, skip to end of timeline
    if (location.hash) {
      tl.progress(1, false);
    }
  }, [location.hash, location.pathname, setLoaderDone, setScrollDisabled, tl]);

  return (
    <div className="full-page-loader full-viewport-height">
      <svg
        version="1.1"
        id="border-top_1_"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 60 60"
        preserveAspectRatio="none"
        xmlSpace="preserve"
        className="border-animation loader-animation"
        opacity="0"
      >
        {logoColors.map((color, i) => (
          <g
            className={`loader-border-${color}`}
            style={{ stroke: color }}
            key={i}
          >
            <line
              id="outer-border-top"
              className="loader-border"
              x1="60"
              y1="0"
              x2="0"
              y2="0"
              vectorEffect="non-scaling-stroke"
            />
            <line
              id="outer-border-left"
              className="loader-border"
              x1="0"
              y1="0"
              x2="0"
              y2="60"
              vectorEffect="non-scaling-stroke"
            />
            <line
              id="outer-border-bottom"
              className="loader-border"
              x1="0"
              y1="60"
              x2="60"
              y2="60"
              vectorEffect="non-scaling-stroke"
            />
            <line
              id="outer-border-right"
              className="loader-border"
              x1="60"
              y1="60"
              x2="60"
              y2="0"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        ))}
      </svg>
      <svg
        version="1.1"
        id="jf-loader-animation"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 60 140"
        style={{ enableBackground: "new 0 0 60 140" }}
        xmlSpace="preserve"
        className="logo-animation loader-animation"
        opacity="0"
      >
        <g>
          {logoColors.map((color, i) => (
            <g className={`jf-${color}`} style={{ stroke: color }} key={i}>
              <path
                className="jf-letter j-path"
                d="M14,20h26v32c0,10.5-8.5,19-19,19h-7"
              />
              <path
                className="jf-letter f-path"
                d="M44,120H20v-13c0-10.5,8.5-19,19-19h7"
              />
              <line
                className="jf-border border-top"
                x1="60"
                y1="4"
                x2="8"
                y2="4"
              />
              <line
                className="jf-border border-left"
                x1="4"
                y1="0"
                x2="4"
                y2="132"
              />
              <line
                className="jf-border border-bottom"
                x1="0"
                y1="136"
                x2="52"
                y2="136"
              />
              <line
                className="jf-border border-right"
                x1="56"
                y1="140"
                x2="56"
                y2="8"
              />
            </g>
          ))}
        </g>
        <polygon
          id="mask"
          className="st2"
          points="44,115 39.7,125 45,125 45,115 "
        />
      </svg>
    </div>
  );
}
