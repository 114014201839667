import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { inDevelopment } from "utils/env-utils";

// Contexts
import { UserContext } from "contexts/UserContext";
import { ProjectsContext } from "contexts/ProjectsContext";

// Images
import me from "img/post-it.png";

// Style
import s from "./index.module.scss";

import { Header } from "components/Header";
import { ProjectCard } from "components/ProjectCard";

export const Grid = () => {
  const { users, usersLoaded } = useContext(UserContext);
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);

  // Data testing
  if (inDevelopment) {
    console.group("Data Status");
    console.log(loaded ? "I'm done loading" : "I'm still loading");
    console.log(
      dataLoaded
        ? "and my data is done loading too!"
        : "but my data is still loading"
    );
    console.log(projects);
    console.groupEnd();
  }

  const aboutProject = {};

  return (
    <div className={s.pageBackground}>
      <div className={s.grid}>
        {projects.map((project, i) => (
          <ProjectCard project={project} key={i} />
        ))}
        <ProjectCard
          project={{
            title: "About Jono",
            tile: me,
            slug: "a-cool-guy",
            bannerImageBackgroundColor: "red",
          }}
        />
      </div>
    </div>
  );
};
