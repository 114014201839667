import React, { useEffect, useContext } from "react";
import { useParams, useNavigate, Redirect } from "react-router-dom";

import { inDevelopment } from "utils/env-utils";

import { IntroPicture } from "components/Portfolio/IntroPicture";
import { Intro } from "components/Portfolio/Intro";
import { BodyContent } from "components/Sanity/index";
// import { Footer } from 'components/Portfolio/Footer'

// Contexts
import { ProjectsContext } from "contexts/ProjectsContext";

// Style
import s from "./index.module.scss";
import { useEdgeManager } from "contexts/EdgeManager";

export const ProjectPage = () => {
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);
  let { slug: urlSlug } = useParams(),
    navigate = useNavigate();

  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  const projectSlugs = projects.map((project) => {
    return project.slug;
  });

  const slugMatch = projectSlugs.includes(urlSlug);

  const project = projects.find(({ slug }) => {
    return slug === urlSlug;
  });

  const {
    jonoIs,
    bannerImage,
    bannerImageBackgroundColor,
    headline,
    clientName,
    description,
    hats,
    projectLink,
    gradient,
    slug,
    data,
  } = { ...project };

  const { setEdge, setEdgeColor } = useEdgeManager();

  useEffect(() => {
    if (loaded) {
      if (slugMatch) {
        document.title = `Jono is ${jonoIs}`;
        setEdgeColor(bannerImageBackgroundColor);
        setEdge(false);
      } else {
        navigate("/");
      }
    }
  }, [
    loaded,
    navigate,
    project,
    jonoIs,
    slugMatch,
    setEdge,
    setEdgeColor,
    bannerImageBackgroundColor,
  ]);

  if (inDevelopment) {
    console.group("Data Status");
    console.log(loaded ? "I'm done loading" : "I'm still loading");
    console.log(
      dataLoaded
        ? "and my data is done loading too!"
        : "but my data is still loading",
    );
    console.log(project);
    console.groupEnd();
  }

  //A change
  return (
    loaded &&
    slugMatch && (
      <div className={s.projectPage}>
        <IntroPicture
          image={bannerImage}
          backgroundColor={bannerImageBackgroundColor}
        />
        <Intro
          title={headline}
          clientName={clientName}
          description={description}
          headline={headline}
          hats={hats}
          link={projectLink}
          gradientValues={gradient}
        />
        {/* Render below the fold on data fetch */}
        <div className={s.projectContent}>
          {dataLoaded && data && <BodyContent blocks={data} />}
        </div>
        {/* {loaded &&  
        <Footer currentProject={ urlSlug } projects={ projects } />
      } */}
      </div>
    )
  );
};
