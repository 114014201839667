import React, { useState, useContext, useRef } from "react";
import gsap from "gsap"
// IMPORT LAST to benefit from cascading style overrides
import s from "./index.module.scss";

export const EdgeContext = React.createContext({
  setEdgeColor: () => {},
});


export const EdgeManager = ({ children }) => {
  const [edge, setEdge] = useState(true);
  var style = getComputedStyle(document.body)
  const bgColor = style.getPropertyValue('--bg-color') // #336699
  const [edgeColor, setEdgeColor] = useState(bgColor);
  const [scrollDisabled, setScrollDisabled] = useState(true);
  const resetEdgeColor = () => setEdgeColor(bgColor);
  

  gsap.to("#logo", {fill: "currentColor"})

  return (
    <EdgeContext.Provider
      value={{
        setEdgeColor,
        resetEdgeColor,
        scrollDisabled,
        setScrollDisabled,
        edge,
        setEdge,
        edgeColor,
      }}
    >
      <div
        className={`${s.pageBackground} ${edge ? "" : s.hidden}`}
        id="edge"
      >
        <div
          className={`${s.pageContainer} ${scrollDisabled ? "no-scroll" : ""}`}
        >
          {children}
        </div>
      </div>
    </EdgeContext.Provider>
  );
};

export const useEdgeManager = () => {
  return useContext(EdgeContext);
};
