const getBlockContent = (fieldName) => `${fieldName}[]{
  ...,
  ...select(
    _type == "oneOff" => {
      ...,
      options
    }
  ),
  ...select(
    _type == "twoPicture" => {
      "image1": image1.asset -> url,
      "image2": image2.asset -> url
    }
  ),
  ...select(
    _type == "fullWidthImage" => {
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "fullGridImage" => {
      ...,
      "image": imagex.asset -> url,
      "backgroundColor": backgroundColor.hex
    }
  ),
  ...select(
    _type == "headerAndBody" => {
      ...,
      "backgroundColor": backgroundColor.hex
    }
  ),
  ...select(
    _type == "pullquote" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "thinGridImage" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "tileArray" => {
      ...,
      tileArray[]{
        ...,
        "image": image.asset -> url
      }
    }
  ),
  ...select(
    _type == "cropFullWidthImage" => {
      ...,
      "image": image.asset -> url
    }
  ),
  ...select(
    _type == "logoBlock" => {
      ...,
      "image": image.asset -> url
    }
  ),
}`;

export const homepageQuery = `*[_type=="homeContent"][0] | {
  greeting
}`;

export const userQuery = `*[_type=="user"] | {
  username,
  emoji,
  "edgeColor": edgeColor.hex,
  "slug": slug.current,
  message1,
  message2,
  message3,
  email,
  selectedProjects[]->{..., "tile": tile.asset -> url, "slug": slug.current,}
}`;

export const selectedProjectsQuery = `*[_type=="project" && active == true] | order(date desc)  {
    jonoIs,
    active,
    clientName,
    "slug": slug.current,
    date,
    headline,
    description,
    projectLink,
    title,
    "gradient": gradient[].hex,
    "tile": tile.asset -> url,
    "bannerImage": bannerImage.asset -> url,
    "bannerImageBackgroundColor": bannerImageBackgroundColor.hex,
    hats
}`;

export const selectedProjectsWithDataQuery = `*[_type=="project" && active == true] | order(date desc) {
    "slug": slug.current,
    "tile": tile.asset -> url,
    "gradient": gradient[].hex,
    "bannerImage": bannerImage.asset -> url,
    "bannerImageBackgroundColor": bannerImageBackgroundColor.hex,
    "data": ${getBlockContent("body")},
    ...
}`;
